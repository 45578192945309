<template>
  <el-card style="min-height: 45rem">

  <el-row :gutter="20">
      <el-col :span="12">
        <el-form v-loading="loading" :model="form" label-width="9rem" style="padding: 2rem;">
          <el-form-item label="启用借权发佣金">
            <el-switch v-model="form.enable"></el-switch>
          </el-form-item>
          <el-form-item label="公众号AppId">
            <el-input v-model="form.app_id"></el-input>
          </el-form-item>
          <el-form-item label="公众号AppSecret">
            <el-input v-model="form.app_secret"></el-input>
          </el-form-item>
          <el-form-item label="微信支付商户号">
            <el-input v-model="form.mch_id"></el-input>
          </el-form-item>
          <el-form-item label="微信支付密钥">
            <el-input v-model="form.api_key"></el-input>
          </el-form-item>
          <el-form-item label="支付证书序列号">
            <el-input v-model="form.wx_pay_serial_no"></el-input>
          </el-form-item>
          <el-form-item label="支付V3密钥">
            <el-input v-model="form.wx_pay_api_v3_key"></el-input>
          </el-form-item>
          <el-form-item label="启用商家转账到零钱">
            <el-switch v-model="form.wx_pay_cash_use_v3"></el-switch>
            <div class="y-desc">开启后佣金打款由 企业付款到零钱 切换为 商家转账到零钱</div>
          </el-form-item>
          <el-form-item >
            <el-button @click="submit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12">
        <el-form v-loading="pemLoading" :model="form" label-width="9rem" style="padding: 2rem;">
          <el-form-item label="apiclient_cert.pem">
            <y-upload-wx-substitute-cert @load="loadPem" mode="cert" v-model="cert"></y-upload-wx-substitute-cert>
          </el-form-item>
          <el-form-item label="apiclient_key.pem">
            <y-upload-wx-substitute-cert @load="loadPem" mode="key" v-model="key"></y-upload-wx-substitute-cert>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import YUploadWxSubstituteCert from "@/components/y-form/y-upload/y_upload_wx_substitute_cert";
export default {
  name: "wxOfficialSubstitute",
  components:{YUploadWxSubstituteCert},
  data(){
    return{
      form:{
        enable:false,

        app_id:"",
        app_secret:"",

        mch_id:"",
        api_key:"",

        wx_pay_cash_use_v3:false,
        wx_pay_api_v3_key:"",
        wx_pay_serial_no:"",
      },
      loading:false,

      pemLoading: false,
      cert: false,
      key: false,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    loadPem() {
      this.pemLoading = true;
      this.$u.api.attach.wxSubstitutePayPemLoad().then(res=>{
        this.pemLoading = false;
        this.cert = res.cert;
        this.key = res.key;
      })
    },
    load(){
      this.loading = true;
      this.$u.api.shop.sys.wechatOfficialSubstitute().then(res=>{
        this.form = res;
        this.loading = false;
      })
    },
    submit(){
      this.$u.api.shop.sys.wechatOfficialSubstituteEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    }
  }
}
</script>

<style scoped>
.y-desc{
  line-height: 1rem
}
</style>